// import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { fab } from "@fortawesome/free-brands-svg-icons"
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa'
import logoHeader from '../images/logo-header-2.png'

const Header = () => (
  <header>
    <nav className="navbar">
      <div className="navbar-brand">
        <img
          className="navbar-logo"
          src={logoHeader}
          width="200"
          height="40"
          alt="Midtown Trainers Logo"
        />
      </div>

      <ul className="navbar-nav">
        <div className="nav-flex">
          <li className="nav-link">
            <a href="#about">About</a>
          </li>
          <li className="nav-link">
            <a href="#trainers">Trainers</a>
          </li>
          <li className="nav-link">
            <a href="#pricing">Pricing</a>
          </li>
          <li className="nav-link">
            <a className="nav-item" href="#contact">
              Contact
            </a>
          </li>
        </div>
        <div className="nav-flex">
          <li className="nav-social">
            <a
              className="cta-link"
              href="https://www.facebook.com/Midtown-Personal-Training-101862214532258/"
            >
              <FaFacebookSquare size={24} />
            </a>
          </li>
          <li className="nav-social">
            <a
              className="cta-link"
              href="https://www.instagram.com/midtownpersonaltraining/"
            >
              <FaInstagram size={24} />
            </a>
          </li>
        </div>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
