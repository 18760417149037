import React from 'react'
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa'

const Footer = () => (
  <footer className="footer">
    <p>© {new Date().getFullYear()} Midtown Personal Training</p>

    <ul className="nav">
      <div className="nav-flex">
        <li className="nav-item">Check us out on Facebook!</li>
        <li className="nav-social">
          <a
            className="cta-link"
            href="https://www.facebook.com/Midtown-Personal-Training-101862214532258/"
          >
            <FaFacebookSquare size={24} />
          </a>
        </li>
      </div>

      <div className="nav-flex">
        <li className="nav-item">And Instagram!</li>
        <li className="nav-social">
          <a
            className="cta-link"
            href="https://www.instagram.com/midtownpersonaltraining/"
          >
            <FaInstagram size={24} />
          </a>
        </li>
      </div>
    </ul>
    <p>
      Built by{' '}
      <a href="https://www.forgecs.com/" className="cta-link">
        Forge Creative Systems
      </a>
    </p>
  </footer>
)

export default Footer
